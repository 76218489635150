<template>
  <div id="viewContainer" v-if="contract.approvalStatus != null &&contract.deleteStatus==0">
    <div class="view-toolbar">
      <PDFPageDisplay
          ref="pageRef"
          v-if="totalPage"
          v-model:toolPageIndex="toolPageIndex"
          :total-page="totalPage"
          :no-input="true"
      ></PDFPageDisplay>
    </div>
    <div class="view-content" v-loading="fileEditStatus == 0" element-loading-text="文档正在处理中">
      <PdfPreview
          v-if="previewContract.id"
          :contractId="previewContract.id"
          :version-id="previewContract.version ? previewContract.version.id : null"
          :originVersionId="previewContract.version.originVersionId"
          :view-ocr-mode="previewContract.viewMode == 1"
          ref="pdf-preview"
          :default_scale="require('../../../components/common/pdfView').ScaleSpecialValue.PAGE_WIDTH"
          :previewSuccess="pdfPreviewSuccess"
      ></PdfPreview>
      <h3 v-else>没有扫描版本</h3>
    </div>

  </div>
  <error v-else-if="contract.deleteStatus" :error-msg="'文档已被删除'"
         :btn-handler="()=>{$router.back()}"
         :btn-msg="'返回'">
  </error>
</template>

<script>
import PdfPreview from "../../../components/PdfPreview";
import {getContractVersionDetail, getContractViewDetail, getPdfOcrStatus, requestHeaderMixin} from "../../../api/api";
import PDFPageDisplay from "../../../components/contract/PDFPageDisplayer";
import {appPostMessage, colorLog, DomEventListenerManager} from "../../../util";
import {
  getPdfUrlByFileId
} from "../../../components/common/pdfView";
import {ElMessage} from "element-plus";
import {ContractApprovalStatus} from "../../../constant/contract";
import {CommentPermissionMode} from "../../../components/common/constant";
import {ContractFileType} from "../../../components/common/contractConstant";
import Error from "../../Error";

export default {
  name: "contractViewApp",
  data(){
    return {
      inhibitJumpPage:false,
      contract:{
        id: this.$route.query.id,
      },
      supplements:[],
      supplement:null,
      previewContract:{},
      version:null,
      pdfInfo:null,
      toolPageIndex:1,
      totalPage:0,
      fileEditStatus:1,
      viewMode:0,//1为扫描版
      domEventListenerManager:new DomEventListenerManager(),
      // scrolled:false,
    }
  },
  mixins:[requestHeaderMixin],
  watch:{
    toolPageIndex(newVal){
      if(this.inhibitJumpPage) return this.inhibitJumpPage = false;
      this.setPdfPage(Number(newVal));
    },
  },
  methods:{
    pdfPreviewSuccess(pdfContainerId,pdfInfo){
      appPostMessage({type:'viewMode',value:this.viewMode});

      let pdfContainer = document.querySelector('#'+pdfContainerId);
      pdfContainer.scrollTop = 0;
      // this.domEventListenerManager.registerListener(pdfContainer,'scroll',(e)=>{
      //   if(e.currentTarget.scrollTop !== 0){
      //     this.scrolled = true;
      //   }else{
      //     this.scrolled = false;
      //   }
      // })

      this.setPDFInfo(pdfInfo);
      this.bindPDFEventListeners();
    },
    setPDFInfo(pdfInfo){
      this.pdfInfo = pdfInfo;
      this.totalPage = pdfInfo.pdfDoc.numPages;
    },
    bindPDFEventListeners(){
      this.pdfInfo.eventBus._on('pagechanging',(data)=>{
        this.inhibitJumpPage = true;
        this.toolPageIndex = data.pageNumber;
        this.$refs.pageRef.checkPageIndex();
      })
    },
    setPdfPage(number){
      if(!this.pdfInfo) return;

      this.pdfInfo.pdfViewer.currentPageNumber = number;
    },
    checkPdfToProStatus(){
      if(this.version.fileType!==ContractFileType.PDF) return;
      getPdfOcrStatus(this.contract.id).then(res=>{
        if(res.data.code === 0) {
          if (res.data.data) {
            if (this.fileEditStatus === 0) {
              this.$refs["pdf-preview"].checkStatus();
            }
            this.fileEditStatus = 1;
          }
          else  {
            this.fileEditStatus = 0
            this.checkPdfTimeout = setTimeout(() => {
              this.checkPdfToProStatus();
            }, 3000)
          }
        }else {
          this.fileEditStatus = 2;
        }
      }).catch(err=>{
        this.fileEditStatus = 2;
        console.error(err);
        this.metaMessage.error("获取pdf处理信息失败")
      })
    },
    supplementChanged(){
      colorLog.yellow(this.supplement)
      this.viewMode=0;
      this.previewContract.id = this.supplement.id;
      this.previewContract.version=this.supplement.version;
      this.previewContract.viewMode=0;
      if(this.previewContract.version.fileType===ContractFileType.PDF){
        this.previewContract.viewMode=1;
        this.viewMode=1;
        this.checkPdfToProStatus();
      }

    },
  },
  created() {
    getContractViewDetail(this.contract.id).then(res => {
      colorLog.log('getContractViewDetail   res:', res);
      if (res.data.code === 0) {
        this.contract = res.data.data;
        this.version = res.data.data.version;

        if(this.contract.parentId){
          this.$router.replace({
            name:'contractViewApp',
            query:{
              id:this.contract.parentId,
              supplementId:this.contract.id,
              query:this.$route.query.query
            }
          }).then(()=>{
            window.location.reload()
          })

          return;
        }

        this.previewContract.id=this.contract.id;
        this.previewContract.version=this.contract.version;
        this.previewContract.viewMode=this.viewMode;

        this.supplements = res.data.data.supplementalContracts;
        this.supplement = {id:this.contract.id,title:'合同文本',version:this.contract.version}
        this.supplements.unshift(this.supplement);

        if(this.$route.query.supplementId){
          this.supplement = this.supplements.find((item)=>item.id==this.$route.query.supplementId)
          this.supplementChanged();
        }

        if(this.version.fileType===ContractFileType.PDF){
          this.checkPdfToProStatus()
        }
      }
    })

    //在window上挂载一个receiveMessage方法，RN会调用
    window.receiveMessage = async (msg) => {
      let operation = msg['type'];
      if(operation == 'jumpTo'){
        this.toolPageIndex = msg.value;
        this.setPdfPage(Number(msg.value));

      }else if(operation == 'viewMode'){
        this.viewMode = msg.value;
        this.previewContract.viewMode = msg.value;
      }
    }
  },
  components:{Error, PDFPageDisplay, PdfPreview}
}
</script>

<style scoped>
p{
  margin: 0;
}

#viewContainer{
  width: 100vw;
  height: 100vh;
  display:flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;
}

.view-toolbar{
  position: absolute;
  z-index: 1;
  left: 5px;
  top: 5px;
  padding:0 3px;
  transition:.4s;
  background: rgba(0,0,0,.5);
  border-radius: 4px;
}
.view-toolbar .pdf-page-display{
  color: #fff;
  pointer-events: none;

}
.view-toolbar.scrolled{
  box-shadow: 0 2px 0px var(--gray-2);
}

.view-content{
  flex: 1;
  min-height: 0px;
  width: 100%;
  position: relative;
}
/*pdf组件的内部样式*/
.view-content >>> .pdf-container{
  height: 100%;
  overflow: auto;
}

</style>
